import axios from 'axios';
import React, { useState, useRef, useEffect, useContext } from 'react';
import { ChevronBarLeft, ChevronBarRight, ChevronLeft, ChevronRight, PencilSquare, Trash } from 'react-bootstrap-icons';
import { UserContext } from '../../App';
import './CustomerForm.css'
axios.defaults.withCredentials = true;

// Utility functions for color and date calculations
const getDaysLeftColor = (daysLeft) => {
    if (daysLeft > 20) return '#28A745'; // Green
    if (daysLeft <= 20 && daysLeft > 10) return '#FFC107'; // Yellow
    return '#DC3545'; // Red
};

const calculateDaysLeft = (followUpDate) => {
    const today = new Date();
    const followUp = new Date(followUpDate.split('T')[0]);
    const difference = followUp - today;
    const daysLeft = Math.ceil(difference / (1000 * 60 * 60 * 24));
    return daysLeft;
};

const trimString = (str) => str.split('T')[0];

// Main component for adding customers
const AddCustomer = () => {
    const [showForm, setShowForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [msg, setMsg] = useState(null);
    const [msg_type, setMsg_Type] = useState(null);
    const [completeCustomerList, setCompleteCustomerList] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [currentDate, setCurrentDate] = useState('');
    const [sortState, setSortState] = useState({ field: 'original', order: 'original' });
    const formRef = useRef(null);
    const { user } = useContext(UserContext);

    // State for customer form data
    const [newCustomerData, setNewCustomerData] = useState({
        name: '',
        mobile: '',
        email: '',
        village: '',
        district: '',
        empName: '',
        city: '',
        estate: '',
        zipCode: '',
        source: '',
        followUp_date: '',
        status: '3',
        remark: ''
    });

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        village: '',
        city: '',
        state: '',
        address: '',
        zipCode: '',
        uniqueID: user.email + user.username
    });
    const [Id, setID] = useState(null)

    // Event listener to close the form when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (formRef.current && !formRef.current.contains(event.target)) {
                setShowForm(false);
                setShowEditForm(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const [refresh, setRefresh] = useState(false)
    // Fetch all customers when the component mounts
    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const res = await axios.get('https://salesmanagementbackend.basic2ai.info/api/all/customer');
                // const res = await axios.get('http://localhost:5020/api/all/customer');
                setCompleteCustomerList(res.data.data);
                setOriginalData(res.data.data);
            } catch (err) {
                console.error('Error fetching customers:', err);
            }
        };

        fetchCustomers();
    }, [refresh]);

    // Set current date
    useEffect(() => {
        setCurrentDate(new Date().toISOString().split('T')[0]);
    }, []);
    const [newRemark, setNewRemark] = useState(null)

    // Handle input values with validation
    const handleNumberValue = (e, len) => {
        const value = e.target.value;
        if (value.length <= len) {
            setNewCustomerData({ ...newCustomerData, [e.target.name]: value });
            setFormData({ ...formData, [e.target.name]: value });
        } else {
            alert(`Limit exceeds for length ${len}`);
        }
    };

    // Handle form submit with data validation and API calls
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Submitting new customer...");

        try {
            // Post to backend
            // await axios.post('http://localhost:5020/register/customer', { newCustomerData });
            await axios.post('https://salesmanagementbackend.basic2ai.info/register/customer', { newCustomerData });

            setMsg("Customer successfully created in both systems.");
            setMsg_Type("success");
            setShowForm(false);

            // Refresh customer list after submission
            // const res = await axios.get('http://localhost:5020/api/all/customer');
            const res = await axios.get('https://salesmanagementbackend.basic2ai.info/api/all/customer');
            console.log(res.data)
            setCompleteCustomerList(res.data.data);
            setOriginalData(res.data.data);

            // Reset form data
            setNewCustomerData({
                name: '',
                mobile: '',
                email: '',
                village: '',
                district: '',
                empName: '',
                city: '',
                estate: '',
                zipCode: '',
                source: '',
                followUp_date: '',
                status: '3',
                remark: ''
            });

            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                village: '',
                city: '',
                state: '',
                address: '',
                zipCode: ''
            });
        } catch (error) {
            console.error('Error creating customer:', error);
            setMsg("There was an error creating the customer.");
            setMsg_Type("danger");
        }
    };
    const handleUpdate = () => {
        const { name, email, mobile } = selectedCustomer;
        const updatedData = {
            name: newCustomerData.name || name,
            email: newCustomerData.email || email,
            mobile: newCustomerData.mobile || mobile,
            village: newCustomerData.village || selectedCustomer.village,
            district: newCustomerData.district || selectedCustomer.district,
            city: newCustomerData.city || selectedCustomer.city,
            estate: newCustomerData.estate || selectedCustomer.estate,
            zipCode: newCustomerData.zipCode || selectedCustomer.zipCode,
            source: newCustomerData.source || selectedCustomer.source,
            followUp_date: newCustomerData.followUp_date || selectedCustomer.followUp_date,
            status: newCustomerData.status || selectedCustomer.status,
            remark: newCustomerData.remark || selectedCustomer.remark,
        };

        if (window.confirm(`Do you really want to update the entry for ${name}, ${mobile}, and ${email}?`)) {
            axios.post(`https://salesmanagementbackend.basic2ai.info/api/customers/update/${name}/${email}/${mobile}`, { newCustomerData: updatedData })
                .then(res => {
                    // console.log(res.data);
                    if (res.data.msg_type === 'good') {
                        setMsg("Customer updated successfully.");
                        setMsg_Type("success");
                        setRefresh(!refresh)
                    } else {
                        setMsg("Data Not Found...");
                        setMsg_Type("danger");
                    }
                });
        }
    };
    // Handle change in input fields
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const [localSource, setLocalsource] = useState(null)

    // Update customer data on input change
    const handleSetValues = (e, name) => {
        if (name === 'name') {
            setNewCustomerData({ ...newCustomerData, name: e.target.value })
            setFormData({ ...formData, first_name: e.target.value })
        }
        else if (name === 'email') {
            setNewCustomerData({ ...newCustomerData, email: e.target.value })
            setFormData({ ...formData, email: e.target.value })
        }
        else if (name === 'village') {
            setNewCustomerData({ ...newCustomerData, village: e.target.value })
            setFormData({ ...formData, village: e.target.value })
        }
        else if (name === 'city') {
            setNewCustomerData({ ...newCustomerData, city: e.target.value })
            setFormData({ ...formData, city: e.target.value })
        }
        else if (name === 'state') {
            setNewCustomerData({ ...newCustomerData, estate: e.target.value })
            setFormData({ ...formData, state: e.target.value })
        }
        else if (name === 'source') {
            if (e.target.value === 'Others') {
                setLocalsource('Others')
                setNewCustomerData({ ...newCustomerData, source: null })
            } else {
                setLocalsource(null)
                setNewCustomerData({ ...newCustomerData, source: e.target.value })
            }
            // setFormData({ ...formData, source: e.target.value })
        }
        else if (name === 'inputSource') {
            if (e.target.value === 'Others') {
                setLocalsource('Others')
            } else
                setNewCustomerData({ ...newCustomerData, source: e.target.value })
            // setFormData({ ...formData, source: e.target.value })
        }
        else if (name === 'district') {
            setNewCustomerData({ ...newCustomerData, district: e.target.value })
        }
        else if (name === 'status') {
            setNewCustomerData({ ...newCustomerData, status: e.target.value })
        }
        else if (name === 'empName') {
            setNewCustomerData({ ...newCustomerData, empName: e.target.value })
        }
        if (name === 'followUp_date') {
            setNewCustomerData({ ...newCustomerData, followUp_date: e.target.value })
            // setFormData({ ...formData, name: e.target.value })
        }
        if (name === 'remark') {
            setNewCustomerData({ ...newCustomerData, remark: e.target.value })
            // setFormData({ ...formData, name: e.target.value })
        }
    }

    // Handle table sorting
    const handleSort = (field) => {
        let sortedData;
        let order = sortState.order;

        // Toggle sorting order when the same field is clicked
        if (sortState.field === field) {
            order = order === 'ascending' ? 'descending' : 'ascending';
        } else {
            order = 'ascending';
        }

        if (field === 'original') {
            // If the original field is selected, reset to the original data
            sortedData = originalData;
        } else {
            sortedData = [...completeCustomerList].sort((a, b) => {
                let aValue = a[field];
                let bValue = b[field];

                // Sorting logic for "Days Left" column
                if (field === 'daysLeft') {
                    const aDaysLeft = calculateDaysLeft(a.followUp_date);
                    const bDaysLeft = calculateDaysLeft(b.followUp_date);
                    return order === 'ascending' ? aDaysLeft - bDaysLeft : bDaysLeft - aDaysLeft;
                }

                // Parsing integers for sorting numerical fields like Zip Code
                if (field === 'zipCode') {
                    aValue = parseInt(aValue, 10);
                    bValue = parseInt(bValue, 10);

                    if (isNaN(aValue)) aValue = 0;
                    if (isNaN(bValue)) bValue = 0;

                    return order === 'ascending' ? aValue - bValue : bValue - aValue;
                }

                // Sorting for string fields (city, estate, etc.)
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return order === 'ascending' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                }

                return 0;
            });
        }

        // Update the state with sorted data and current sorting order
        setCompleteCustomerList(sortedData);
        setSortState({ field, order });
    };
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const handleEditDeleteCustomer = (type, customer, id) => {
        if (type === 'edit') {
            setSelectedCustomer(customer);
            setNewCustomerData({}); // Clear previous data
            setShowEditForm(!showEditForm);
            setID(id)
        } else if (type === 'delete') {
            const { id, name, mobile, email } = customer;
            if (window.confirm(`Do you really want to delete the entry for ${id}, ${name}, ${mobile}, and ${email}?`)) {
                axios.delete(`https://salesmanagementbackend.basic2ai.info/delete/${id}/${name}/${email}/${mobile}`)
                    .then(res => {
                        if (res.data.msg_type === 'good') {
                            setRefresh(!refresh)
                        }
                    });
            }
        } else {
            alert('Function Not Available...');
        }
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Slice the list of customers to only include the current page's items
    const currentItems = completeCustomerList && completeCustomerList.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total number of pages
    const totalPages = completeCustomerList && Math.ceil(completeCustomerList.length / itemsPerPage);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleRemark = (remark, name, mobile, email, zipCode) => {
        if (remark) {
            axios.post('https://salesmanagementbackend.basic2ai.info/update/remark', { remark: remark, name: name, mobile: mobile, email: email, zipCode: zipCode })
                .then(res => {
                    if (res.data.success) {
                        setRefresh(true)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
        else {
            alert('Can not save Empty remark')
        }
        setRefresh(false)
    }
    return (
        <div className='p-2'>
            <div className='w-100 d-flex align-items-center justify-content-between'>
                <button className='m-1 btn border-0 rounded-0' style={{ fontSize: '25px', fontWeight: '600' }}>All Customers</button>
                <button
                    className='m-1 btn btn-success border-0 rounded-0'
                    onClick={() => setShowForm(true)}
                >
                    Add Customer
                </button>
            </div>

            {/* Customers Table */}
            <div className='table-responsive'>
                <table className='table table-bordered table-hover'>
                    <thead className='thead'>
                        <tr>
                            {/* Table headers */}
                            <th style={{ verticalAlign: 'middle' }}>#</th>
                            <th onClick={() => handleSort('name')} style={{ cursor: 'pointer', minWidth: '150px', verticalAlign: 'middle' }}>
                                Name {sortState.field === 'name' ? (sortState.order === 'ascending' ? <><b className='text-primary'> &#x25B2;</b>&#x25BD;</> : <>&#x25B3;<b className='text-primary'>&#9660;</b></>) : <b>&#x25B3; &#x25BD;</b>}
                            </th>
                            <th style={{ verticalAlign: 'middle' }}>Phone</th>
                            <th style={{ verticalAlign: 'middle' }}>Email</th>
                            <th style={{ verticalAlign: 'middle' }}>Product</th>
                            <th style={{ verticalAlign: 'middle' }}>Value</th>
                            <th onClick={() => handleSort('city')} style={{ cursor: 'pointer', minWidth: '120px', verticalAlign: 'middle' }}>
                                City {sortState.field === 'city' ? (sortState.order === 'ascending' ? <><b className='text-primary'> &#x25B2;</b>&#x25BD;</> : <>&#x25B3;<b className='text-primary'>&#9660;</b></>) : <b>&#x25B3; &#x25BD;</b>}
                            </th>
                            <th onClick={() => handleSort('estate')} style={{ cursor: 'pointer', minWidth: '120px', verticalAlign: 'middle' }}>
                                State {sortState.field === 'estate' ? (sortState.order === 'ascending' ? <><b className='text-primary'> &#x25B2;</b>&#x25BD;</> : <>&#x25B3;<b className='text-primary'>&#9660;</b></>) : <b>&#x25B3; &#x25BD;</b>}
                            </th>
                            <th onClick={() => handleSort('zipCode')} style={{ cursor: 'pointer', minWidth: '150px', textAlign: 'center', verticalAlign: 'middle' }}>
                                Zip Code {sortState.field === 'zipCode' ? (sortState.order === 'ascending' ? <><b className='text-primary'> &#x25B2;</b>&#x25BD;</> : <>&#x25B3;<b className='text-primary'>&#9660;</b></>) : <b>&#x25B3; &#x25BD;</b>}
                            </th>
                            {/* <th onClick={() => handleSort('daysLeft')} style={{ cursor: 'pointer', minWidth: '150px', textAlign: 'center' }}>
                                Days Left {sortState.field === 'daysLeft' ? (sortState.order === 'ascending' ? <><b className='text-primary'> &#x25B2;</b>&#x25BD;</> : <>&#x25B3;<b className='text-primary'>&#9660;</b></>) : <b>&#x25B3; &#x25BD;</b>}
                            </th> */}
                            <th style={{ verticalAlign: 'middle' }}>Employee</th>
                            <th style={{ verticalAlign: 'middle' }}>Source</th>
                            <th style={{ minWidth: '170px' }}>Remark</th>
                            <th style={{ minWidth: '200px', textAlign: 'center' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems && currentItems.map((item, i) => {
                            const daysLeft = calculateDaysLeft(item.followUp_date);
                            return (
                                <tr key={i}>
                                    <td>{indexOfFirstItem + i + 1}</td> {/* Adjusted for current page index */}
                                    <td>{item.name}</td>
                                    <td>{item.mobile}</td>
                                    <td>{item.email}</td>
                                    <td>{item.village}</td>
                                    <td>{item.district}</td>
                                    <td>{item.city}</td>
                                    <td>{item.estate}</td>
                                    <td style={{ textAlign: 'end' }}>{item.zipCode}</td>
                                    <td style={{ fontWeight: '600', textAlign: 'end', }}>{item.empName}</td>
                                    <td>{item.source}</td>
                                    <td>
                                        <input type='text' value={item.remark} disabled readOnly />
                                        <div className='' style={{ display: `${(showEditForm && i == Id) ? 'block' : 'none'}` }}>
                                            <textarea cols={23} className="form-control my-1" onChange={e => setNewRemark(e.target.value)} />
                                            <button className='btn btn-success rounded-0 w-100' onClick={e => handleRemark(newRemark, item.name, item.mobile, item.email, item.zipCode)}>Save</button>
                                        </div>
                                    </td>
                                    <td className='text-center'>
                                        <button data-target='tooltip' title='Edit' onClick={() => handleEditDeleteCustomer('edit', item, i)} className='btn btn-warning text-white rounded-0 m-1'>
                                            <PencilSquare />
                                        </button>
                                        <button data-target='tooltip' title='Delete' onClick={() => handleEditDeleteCustomer('delete', item, i)} className='btn btn-danger rounded-0 m-1'>
                                            <Trash />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                        {/* {currentItems.map((item, i) => {
                            const daysLeft = calculateDaysLeft(item.followUp_date);
                            return (
                                <tr key={i}>
                                    <td>{indexOfFirstItem + i + 1}</td> 
                                    <td>{item.name}</td>
                                    <td>{item.mobile}</td>
                                    <td>{item.email}</td>
                                    <td>{item.village}</td>
                                    <td>{item.district}</td>
                                    <td>{item.city}</td>
                                    <td>{item.estate}</td>
                                    <td style={{ textAlign: 'end' }}>{item.zipCode}</td>
                                    <td style={{ fontWeight: '600', textAlign: 'end', color: getDaysLeftColor(daysLeft) }}>{daysLeft}</td>
                                    <td>{item.source}</td>
                                    <td>{item.remark}</td>
                                    <td className='text-center'>
                                        <button data-target='tooltip' title='Edit' onClick={() => handleEditDeleteCustomer('edit', item)} className='btn btn-warning text-white rounded-0 m-1'>
                                            <PencilSquare />
                                        </button>
                                        <button data-target='tooltip' title='Delete' onClick={() => handleEditDeleteCustomer('delete', item)} className='btn btn-danger rounded-0 m-1'>
                                            <Trash />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })} */}
                    </tbody>
                </table>

                {/* Pagination Controls */}
                <div className='pagination justify-content-center'>
                    <div className='input-group w-25 d-flex align-items-center'>
                        <b>Current Customers {itemsPerPage}</b> &nbsp;&nbsp;
                        <input className='form-control py-0' style={{ maxWidth: '100px' }} onChange={e => setItemsPerPage(e.target.value > 0 ? e.target.value : itemsPerPage)} min={1} />

                    </div>
                    <button className='btn btn-secondary m-1' disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                        <ChevronLeft />
                    </button>
                    {[...Array(totalPages).keys()].map((page) => (
                        <button
                            key={page}
                            className={`btn border-0 rounded-0 ${currentPage === page + 1 ? 'btn-primary' : 'text-dark'} m-1`}
                            onClick={() => handlePageChange(page + 1)}
                        >
                            {page + 1}
                        </button>
                    ))}
                    <button className='btn btn-secondary m-1' disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>
                        <ChevronRight />
                    </button>
                </div>
            </div>


            {/* Add Customer Form */}
            {showForm && (
                <div
                    className='modal'
                    style={{
                        display: 'block',
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        zIndex: '1000',
                        overflowY: 'auto',
                        transition: '.3s ease-in-out'
                    }}
                >
                    <div
                        className='modal-content'
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            padding: '20px',
                            margin: '5% auto',
                            maxWidth: '600px',
                            overflow: 'auto',
                            maxHeight: '80vh',
                        }}
                        ref={formRef}
                    >
                        <h2>Add New Customer</h2>
                        {msg && (
                            <div className={`alert alert-${msg_type}`} role="alert">
                                {msg}
                            </div>
                        )}
                        <form onSubmit={handleSubmit}>
                            <div className='mb-3'>
                                <label htmlFor='name' className='form-label'>Name</label>
                                <input
                                    type='text'
                                    id='name'
                                    name='name'
                                    className='form-control'
                                    value={newCustomerData.name}
                                    onChange={(e) => handleSetValues(e, 'name')}
                                    required
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='mobile' className='form-label'>Mobile</label>
                                <input
                                    type='text'
                                    id='mobile'
                                    name='mobile'
                                    className='form-control'
                                    maxLength='10'
                                    value={newCustomerData.mobile}
                                    onChange={(e) => handleNumberValue(e, 10)}
                                    required
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='email' className='form-label'>Email</label>
                                <input
                                    type='email'
                                    id='email'
                                    name='email'
                                    className='form-control'
                                    value={newCustomerData.email}
                                    onChange={(e) => handleSetValues(e, 'email')}
                                    required
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='village' className='form-label'>Product</label>
                                <input
                                    type='text'
                                    id='village'
                                    name='village'
                                    className='form-control'
                                    value={newCustomerData.village}
                                    onChange={(e) => handleSetValues(e, 'village')}
                                    required
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='district' className='form-label'>Value</label>
                                <input
                                    type='text'
                                    id='district'
                                    name='district'
                                    className='form-control'
                                    value={newCustomerData.district}
                                    onChange={(e) => handleSetValues(e, 'district')}
                                    required
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='city' className='form-label'>City</label>
                                <input
                                    type='text'
                                    id='city'
                                    name='city'
                                    className='form-control'
                                    value={newCustomerData.city}
                                    onChange={(e) => handleSetValues(e, 'city')}
                                    required
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='state' className='form-label'>State</label>
                                <input
                                    type='text'
                                    id='state'
                                    name='state'
                                    className='form-control'
                                    value={newCustomerData.state}
                                    onChange={(e) => handleSetValues(e, 'state')}
                                    required
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='zipCode' className='form-label'>Zip Code</label>
                                <input
                                    type='text'
                                    id='zipCode'
                                    name='zipCode'
                                    className='form-control'
                                    maxLength='6'
                                    value={newCustomerData.zipCode}
                                    onChange={(e) => handleNumberValue(e, 6)}
                                    required
                                />
                            </div>
                            <div className='mb-3'>
                                <label className='form-label'>Source</label>
                                <select className='form-control' onChange={e => handleSetValues(e, 'source')}>
                                    <option disabled selected>-Select Source-</option>
                                    <option className='' value={'Google Ads'}>Google Ads</option>
                                    <option className='' value={'Youtube'}>Youtube</option>
                                    <option className='' value={'Facebook'}>Facebook</option>
                                    <option className='' value={'Instagram'}>Instagram</option>
                                    <option className='' value={'Twitter'}>Twitter</option>
                                    <option className='' value={'Whatsapp'}>Whatsapp</option>
                                    <option className='' value={'Others'}>Others</option>
                                </select>
                                {localSource === 'Others' ?
                                    <input
                                        name='inputSource'
                                        required={localSource === 'Others' ? true : false}
                                        type='text'
                                        className='form-control my-1'
                                        placeholder='Please provide the other source, Friends or Relatives (with contact Number), another site (Site name) . . .'
                                        onChange={e => handleSetValues(e, 'inputSource')}
                                    /> : ''
                                }
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='followUp_date' className='form-label'>Follow-Up Date</label>
                                <input
                                    type='date'
                                    id='followUp_date'
                                    name='followUp_date'
                                    className='form-control'
                                    value={newCustomerData.followUp_date}
                                    onChange={(e) => handleSetValues(e, 'followUp_date')}
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='empName' className='form-label'>Employee Name</label>
                                <input
                                    type='text'
                                    id='district'
                                    name='district'
                                    className='form-control'
                                    value={newCustomerData.empName}
                                    onChange={(e) => handleSetValues(e, 'empName')}
                                    required
                                />
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='status' className='form-label'>Status</label>
                                <select
                                    id='status'
                                    name='status'
                                    className='form-control'
                                    value={newCustomerData.status}
                                    onChange={(e) => handleSetValues(e, 'status')}
                                >
                                    <option value='3' disabled selected>--Select Status--</option>
                                    <option value='0'>Pending</option>
                                    <option value='1'>Completed</option>
                                    <option value='2'>Rejected</option>
                                </select>
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='remark' className='form-label'>Remark</label>
                                <textarea
                                    id='remark'
                                    name='remark'
                                    className='form-control'
                                    value={newCustomerData.remark}
                                    onChange={(e) => handleSetValues(e, 'remark')}
                                />
                            </div>
                            <div className='mb-3'>
                                {msg && (
                                    <div className={`alert alert-${msg_type}`} role="alert">
                                        {msg}
                                    </div>
                                )}
                                <br />
                                <button type='submit' className='btn btn-success rounded-0 m-1'>
                                    Submit
                                </button>
                                <button type='button' className='btn btn-danger rounded-0 m-1' onClick={() => setShowForm(false)}>
                                    Cancel
                                </button>

                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddCustomer;
