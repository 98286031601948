import axios from 'axios'
import React, { useContext } from 'react'
import { UserContext } from '../App'

axios.defaults.withCredentials = true
const Navbar = () => {
    const { user, login, setLogin } = useContext(UserContext)
    const handleLogout = () => {
        axios.get('https://salesmanagementbackend.basic2ai.info/logout')
            .then(res => {
                // console.log(res.data)
                if (res.status == 200) {
                    setLogin(false)
                    window.location.href = '/'
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <>
            {
                login &&
                <nav className="navbar navbar-expand fixed-top shadow navbar-light bg-light d-flex align-items-center justify-content-between">
                    <a className="navbar-brand" href="/" style={{fontWeight:'700'}}>Lead Management System</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item active">
                                <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="#">Features</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#">Pricing</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Dropdown link
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <a className="dropdown-item" href="#">Action</a>
                                    <a className="dropdown-item" href="#">Another action</a>
                                    <a className="dropdown-item" href="#">Something else here</a>
                                </div>
                            </li> */}
                        </ul>
                        &nbsp;&nbsp;&nbsp;{login && <a href='/register'>{user ? user.role === "superAdmin" ? 'Register New User' : 'Login' : 'Login'}</a>}&nbsp;&nbsp;&nbsp;&nbsp;
                        {login && <button className='btn ms-3' onClick={e => handleLogout()}>Logout</button>} &nbsp;&nbsp;&nbsp;
                    </div>
                </nav>
            }

        </>
    )
}

export default Navbar
